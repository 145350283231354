
@use '@angular/material' as mat;
@import "~@fortawesome/fontawesome-free/css/all.css";

@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
$code-web-primary: mat.define-palette(mat.$indigo-palette);
$code-web-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$code-web-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (A theme consists of configurations for individual theming systems)
$code-web-theme: mat.define-light-theme((color: (primary: $code-web-primary,
        accent: $code-web-accent,
        warn: $code-web-warn,
      )));

// Define a custom color map for the primary color.
$my-app-primary: mat.define-palette((50 : #eae5fb,
      100 : #c9bdf3,
      200 : #a591eb,
      300 : #8065e3,
      400 : #6541dc,
      500 : #4b1dd5,
      600 : #4219cf,
      700 : #3714c7,
      800 : #2c0fbf,
      900 : #1a05b0,
      A100 : #c9bdf3,
      A200 : #a591eb,
      A400 : #6541dc,
      A700 : #3714c7,
      contrast: (50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #ffffff,
        A700 : #ffffff,
      )));

$my-app-theme: mat.define-light-theme((color: (primary: $my-app-primary,
        accent: $code-web-accent,
        warn: $code-web-warn,
      )));

/* Estilo para títulos principales (h1) */
h1 {
  font-family: 'Roboto', sans-serif;
  font-weight: 900; // Black
  font-size: 17px;
}

/* Estilo para títulos secundarios (h2 en adelante) */
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Roboto', sans-serif;
  font-weight: 300; // Light
  font-size: 17px;
}

/* Estilo para el cuerpo del texto (excluyendo encabezados) */
body,
p,
span,
a,
li,
td,
th {
  font-family: 'Roboto', sans-serif;
  font-weight: 300; // Light
  font-size: 15px;
}

/* Estilos globales para el componente Snackbar */

.mat-mdc-snack-bar-container.success-snackbar,
.mat-mdc-snack-bar-container.red-snackbar,
.mat-mdc-snack-bar-container.warning-snackbar {
  width: 200px;
  height: 200px;
  color: white !important;
  font-size: 22px;
}

.mat-mdc-snack-bar-container.success-snackbar,
.success-snackbar button {
  --mdc-snackbar-container-color: rgb(122, 77, 245);
  color: white;

}

.mat-mdc-snack-bar-container.warning-snackbar,
.warning-snackbar button {
  --mdc-snackbar-container-color: (255, 204, 0);
}

.mat-mdc-snack-bar-container.red-snackbar,
.red-snackbar button {
  --mdc-snackbar-container-color: #F44336;
}

.red-snackbar button {
  color: black !important;
  border: solid 2px white;
  background-color: white;
}

/* Resto de las reglas de estilos globales */
/* following style will be applied to every element */
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* write your own css after this part */

body {
  background-color: #eeeeee;
}

.container {
  margin-top: 25px;
  margin-left: 25px;
  margin-right: 25px;
}

.secondary-titles {
  color: #808080;
  /* Puedes cambiar este valor al tono de gris que prefieras */
  margin-bottom: 10px !important;
  text-align: center;
}

.secondary-text {
  color: #808080;
  /* Puedes cambiar este valor al tono de gris que prefieras */
}

.form-card {
  border-radius: 20px;
  padding: 20px;
  margin-top: 35px;

}

.form-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.full-width {
  flex: 1;
  margin-right: 10px;
}


.submit-row {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.submit-button-forms {
  display: flex;
  justify-content: flex-end;

  button {
    font-size: 16px;
    padding: 16px
  }
}

.mb-1 {
  margin-bottom: 3px;
}
.mt-1{
  margin-top: 5px;
}

.spinner {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid blue;
  border-right: 16px solid green;
  border-bottom: 16px solid red;
  border-left: 16px solid pink;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  background-image: url('./assets/img/bot.svg');
  background-size: cover;
  background-repeat: no-repeat;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* Esto asegura que tome todo el alto de la ventana */
  width: 100vw;
  /* Esto asegura que tome todo el ancho de la ventana */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


// Generate styles for only one theme to avoid duplication
@include mat.all-component-themes($my-app-theme);
@import 'variables';